import 'firebase/auth';
import React from 'react';
import { RecoilRoot } from 'recoil';
import { ApolloProvider } from '@apollo/client';
import { client } from './src/config/apolloClient';

export const wrapPageElement = ({ element }) => (
  <RecoilRoot>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </RecoilRoot>
);
