import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-fetch';
import { GRAPH_ENDPOINT, JWT_LOCAL_STORAGE } from './constants';
import { createUploadLink } from 'apollo-upload-client';

const uploadLink = createUploadLink({
  uri: GRAPH_ENDPOINT,
  headers: {
    'keep-alive': 'true',
  },
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(JWT_LOCAL_STORAGE);

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(uploadLink),
});
